<template>
    <div class="page">       
        <div class="content">
            <div style="width: 100%;height: 80vh;background-color: #fff;padding: 20px;">
                <Form class="form" ref="formItem" :model="formItem" :label-width="100">
                    <FormItem label="联系二维码">
                        <div style="display: flex;">
                            <div v-if="formItem.qrcode.path " class="del-div">
                                <img :src="formItem.qrcode.path" alt="" style="width: 90%;height: 90%;">
                                <img class="del-img" src="../../../assets/systemconfig/del.png" alt="" @click="delPath">
                            </div>
                            <Upload 
                                v-else
                                ref="upload"
                                :show-upload-list="false"
                                :format="['jpg', 'jpeg', 'png', 'gif']"
                                :max-size="2048"
                                :on-format-error="handleFormatError"
                                :on-success="pictureSuccess"
                                :on-progress="handleProgress"
                                :headers="headers"
                                :action="uploadImg"
                                >
                                <div class="img-div">
                                    <div class="img-icon">+</div>
                                    <div class="img-text">上传照片</div>
                                </div>
                            </Upload>
                        </div>
                    </FormItem>
                    <FormItem label="联系电话" prop="phone">
                        <Input class="input" v-model="formItem.phone" placeholder="请输入联系电话"></Input>
                    </FormItem>
                    <FormItem label="微信号" prop="wechat_code">
                        <Input class="input" v-model="formItem.wechat_code" placeholder="请输入微信号"></Input>
                    </FormItem>
                    <FormItem label="下单订金" prop="money">
                        <Input class="input" v-model="formItem.money" placeholder="请输入下单订金"></Input>
                    </FormItem>
                    <FormItem label="公司地址" prop="address">
                        <Input class="input" v-model="formItem.address" placeholder="请输入公司地址" type="textarea"></Input>
                    </FormItem>
                    <FormItem class="foot">
                        <Button type="primary" @click="handleSubmit">保存</Button>
                    </FormItem>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
import { getCookies } from "@/utils/helper";
import config from "@/config/index";
import { addSystem,otherSystem } from "@/api/index";
    export default {
        data() {
            return {
                uploadImg: config.apiUrl + "/other/uploads",
                headers: {
                    "authori-zation-huoyun": getCookies("authori-zation-huoyun"),
                },
                formItem: {
                    phone: '',
                    money: '',
                    address:'',
                    qrcode:{path:''},
                    wechat_code:''
                },
                formData:{
                    qrcode_id:'',
                    phone:'',
                    money:'',
                    address:'',
                    about_us:'',
                    wechat_code:''
                },
            }
        },
        created(){
            this.otherSystem()
        },
        methods: {
            // 删除图片
            delPath(){
                this.formItem.qrcode.path = ''
                this.formData.qrcode_id = ''
            },
            otherSystem(){
                otherSystem().then(res=>{
                    this.formItem.phone = res.data.phone
                    this.formItem.wechat_code = res.data.wechat_code
                    this.formItem.money = res.data.money
                    this.formItem.address = res.data.address
                    this.formItem.qrcode.path = res.data.qrcode ? res.data.qrcode.path : ''
                    this.formData.qrcode_id = res.data.qrcode_id
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            handleSubmit (e) {
                if(!this.formItem.phone){
                    this.$Message.error('请输入联系电话')
                    return
                }
                if(!this.formItem.wechat_code){
                    this.$Message.error('请输入微信号')
                    return
                }
                if(!this.formItem.money){
                    this.$Message.error('请输入下单订金')
                    return
                }
                if(!this.formItem.address){
                    this.$Message.error('请输入公司地址')
                    return
                }
                this.formData.qrcode_id = this.formData.qrcode_id ? this.formData.qrcode_id : ''
                this.formData.phone = this.formItem.phone
                this.formData.wechat_code = this.formItem.wechat_code
                this.formData.money = this.formItem.money
                this.formData.address = this.formItem.address
                this.formData.about_us = this.formItem.about_us
                addSystem(this.formData).then(res=>{
                    this.otherSystem() 
                    this.$Message.success(res.msg)                    
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            //文件上传类型错误
            handleFormatError() {
                this.$Message.warning("暂不支持上传此格式");
            },
            // 文件上传时
            handleProgress() {
                const msg = this.$Message.loading({
                    content: "上传中...",
                    duration: 0,
                });
                setTimeout(msg, 3000);
            },
            // 上传成功
            pictureSuccess(e) {
                this.formItem.qrcode.path = e.data.path
                this.formData.qrcode_id = e.data.id
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
        },
    }
</script>

<style lang="scss" scoped>
.del-div{
    width: 100px;
    height: 100px;
    margin-right: 20px;
    position: relative;
    display: flex;
    justify-content:center;
    align-items: center;
}
.del-div:hover .del-img{
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.del-img{
    display: none;
}
.page{
    background-color: #F5F9FA;
    height: 100%;
}
.head{
    width: 100%;
    height: 70px;
    background-color: #fff;
    margin-bottom: 10px;
    div{
        float: left;
        margin: 10px 0 0 10px;
    }
}
.content{
    width: 100%;
    padding: 0 20px;
    border-radius: 10px;
    position: relative;
    .form{
        margin-top: 20px;
        .img-div{
            width: 100px;
            height: 100px;
            border: 1px dashed #B8C4D1;
            background: #F5F9FA;
            text-align: center;
            cursor: pointer;
            .img-icon{
                height: 80px;
                font-size: 60px;
                line-height: 80px;
            }
            .img-text{
                height: 14px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                line-height: 14px;
            }
        }
        .input{
            width: 200px;
        }
        .foot{
            position: absolute;
            width: 95%;
            bottom: 0;
            padding: 10px 0;
            border-top: 1px solid #ccc;
            margin: 10px 0;
            text-align: center;
        }
    }
}
</style>